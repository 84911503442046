<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Create Special Earning</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link :to="{name: 'earning-deductions'}">Earning Deductions</router-link>
                </li>
                <li class="breadcrumb-item active">
                    <router-link :to="{name: 'special-earnings'}">
                        <span> Special Earning Deductions</span>
                    </router-link>
                </li>
                <li class="breadcrumb-item active">Create Special {{ this.str_title(this.SpecialEarningDeduction.type) }}</li>
            </ol>
        </nav>
        <br />
        <div class="container-fluid">
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            <div class="col-md-8">
                <form>

                    <div class="form-group row">
                        <label class="col-md-3">
                            {{ this.str_title(this.SpecialEarningDeduction.type) }} Name
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-9">
                            <input
                                type="text"
                                class="form-control"
                                aria-describedby="fathernameHelpInline"
                                v-model="SpecialEarningDeduction.name"
                                @change="textTitleCase($event, 'name')"
                                autocomplete="off"
                            />
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-3">
                            Employee
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-9">
                            <EmployyeList @emp_id="getEmpId"></EmployyeList>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-3">
                            Month
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-9">
                            <b-form-select 
                                v-model="SpecialEarningDeduction.month"
                                :options="months"
                            ></b-form-select>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-3">
                            Year
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-9">
                            <b-form-select 
                                v-model="SpecialEarningDeduction.year"
                                :options="years"
                            ></b-form-select>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-3">
                            Amount
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-9">
                            <input
                                type="number"
                                class="form-control"
                                aria-describedby="fathernameHelpInline"
                                v-model="SpecialEarningDeduction.value"
                                autocomplete="off"
                            />
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-md-3">
                            Comment
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-9">
                            <textarea
                                class="form-control"
                                id="address1FormControlTextarea1"
                                v-model="SpecialEarningDeduction.comment"
                                autocomplete="off"
                                rows="3"
                            ></textarea>
                        </div>
                    </div>

                    <div class="form-group row" style="float:right;">
                        <div class="offset-xs-3 col-xs-9">
                            <button
                                type="button"
                                class="btn btn-primary text-right ma-10px"
                                v-on:click="createSpecialEarningDeduction()"
                            >Submit</button>
                        </div>
                        <div class="offset-xs-3 col-xs-9">
                            <button
                                type="button"
                                class="btn btn-secondary ma-10px"
                                @click="cancel()"
                            >Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import NotFound from "../../../errors/NotFound";
import default_error from "../../../../assets/js/global";
import EmployyeList from "@/components/EmployeeList.vue"
export default {
    components: {
        NotFound,
        EmployyeList
    },
    data() {
        return {
            SpecialEarningDeduction: {
                emp_id:"",
                month:"",
                year:"",
                name:"",
                value:"",
                comment:"",
                type:""
            },
            months: [
                {value: "", text:"Select Month"},
                {value: "1", text: "January"},
                {value: "2", text: "February"},
                {value: "3", text: "March"},
                {value: "4", text: "April"},
                {value: "5", text: "May"},
                {value: "6", text: "June"},
                {value: "7", text: "July"},
                {value: "8", text: "August"},
                {value: "9", text: "September"},
                {value: "10", text: "October"},
                {value: "11", text: "November"},
                {value: "12", text: "December"},

            ],
            years: [
                {value: "", text: "Select Year"},
                {value: new Date().getFullYear()-5, text: new Date().getFullYear()-5 },
                {value: new Date().getFullYear()-4, text: new Date().getFullYear()-4 },
                {value: new Date().getFullYear()-3, text: new Date().getFullYear()-3 },
                {value: new Date().getFullYear()-2, text: new Date().getFullYear()-2 },
                {value: new Date().getFullYear()-1, text: new Date().getFullYear()-1 },
                {value: new Date().getFullYear(), text: new Date().getFullYear() },
                {value: new Date().getFullYear()+1, text: new Date().getFullYear()+1},
            ],
            api_error: "",
            access: false,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
        };
    },
    created() {
        this.access = this.hasPermission("CAN_MANAGE_EARNING_DEDUCTIONS");
        this.SpecialEarningDeduction.type = this.$route.params.type;
        this.SpecialEarningDeduction.month = this.$route.params.month;
        this.SpecialEarningDeduction.year = this.$route.params.year;
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },
        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },

        textTitleCase(event, key) {
            this.SpecialEarningDeduction[key] = this.strTitleCase(event.target.value);
        },

        getEmpId(id) {
            this.SpecialEarningDeduction.emp_id = id;
        },

        cancel(){
            this.$router.push({
                name: "special-earnings",
                query:{ month:this.$route.params.month,year:this.$route.params.year }
            }).catch(()=>{});
        },
        getMonth(month){
            var month_name = this.months[month];
            return month_name.text;
        },
        createSpecialEarningDeduction: function() {
            this.startProgressBar();
            const query = this.getAuthHeaders();
            query["params"] = {};
            this.axios
                .post(this.$api.create_special_group, this.SpecialEarningDeduction, query)
                .then(response => {
                    if (response.data.error) {
                        this.apiError = response.data.message;
                        this.updateProgressBar(true);
                        return;
                    }
                    this.$router.push({
                        name: "special-earnings",
                        query:{ month:this.SpecialEarningDeduction.month,year:this.SpecialEarningDeduction.year }
                    }).catch(()=>{});
                })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.updateProgressBar(false);
                    this.showAlert();
                    window.scrollTo(0, 0);
                });
        },
    }
};
</script>
<style lang="scss">
@import "../../../../assets/css/custom.scss";


.vdpComponent > input {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        "Noto Sans", Ubuntu, Cantarell, "Helvetica Neue", sans-serif,
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
        "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
}
.vdpComponent > input {
    padding: 0.375rem 0.75rem;
    height: calc(1.5em + 0.75rem + 2px);
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    width: 100%;
}
.vdpClearInput {
    display: none;
}
.vdpComponent.vdpWithInput > input {
    padding-right: 0px;
}
.vdpOuterWrap.vdpFloating {
    z-index: 1000;
}
</style>
